"use client";

import { AuthErrorCode } from "@/features/auth/utils";
import { useSearchParams } from "next/navigation";
import {
    createContext,
    useContext,
    useState,
    ReactNode,
    Context,
    Dispatch,
    SetStateAction,
    useEffect,
} from "react";

interface AuthProviderProps {
    children: ReactNode;
}

interface AuthContextType {
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    errorMessage: AuthMessage | null;
    setErrorMessage: Dispatch<SetStateAction<AuthMessage | null>>;
    successMessage: AuthMessage | null;
    setSuccessMessage: Dispatch<SetStateAction<AuthMessage | null>>;
}

export type AuthMessage = {
    keyName: string;
    defaultValue: string;
};
const testMessage = {
    keyName: "test",
    defaultValue: "אירעה שגיאה לא צפויה",
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuthContext = () => {
    const context = useContext(
        AuthContext as Context<AuthContextType | undefined>
    );
    if (!context) {
        throw new Error("useAuthContext must be used within a AuthContext");
    }
    return context;
};

export default function AuthProvider({
    children,
}: AuthProviderProps): JSX.Element {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<AuthMessage | null>(null);
    const [successMessage, setSuccessMessage] = useState<AuthMessage | null>(
        null
    );

    const searchParams = useSearchParams();
    const errorFromSearchParams = searchParams.get("error");
    const codeFromSearchParams =
        (searchParams.get("code") as AuthErrorCode) || undefined;

    useEffect(() => {
        if (errorFromSearchParams || codeFromSearchParams) {
            console.log({ errorFromSearchParams, codeFromSearchParams });

            switch (codeFromSearchParams) {
                case "email_not_found":
                    setErrorMessage({
                        keyName: "auth.error.email-not-found",
                        defaultValue: "המייל שהוזן לא נמצא",
                    });
                    break;
                case "invalid_credentials":
                    setErrorMessage({
                        keyName: "auth.error.invalid-credentials",
                        defaultValue: "המייל או הסיסמה שהוזנו שגויים",
                    });
                    break;
                case "password_login_not_available":
                    setErrorMessage({
                        keyName: "auth.error.password-login-not-available",
                        defaultValue: "לא קיימת סיסמה למייל שהוזן",
                    });
                    break;
                default:
                    setErrorMessage({
                        keyName: "auth.error.default",
                        defaultValue: "אירעה שגיאה לא צפויה",
                    });
            }
        }
    }, [codeFromSearchParams, errorFromSearchParams]);

    const value: AuthContextType = {
        loading,
        setLoading,
        errorMessage,
        setErrorMessage,
        successMessage,
        setSuccessMessage,
    };

    return (
        <AuthContext.Provider value={value as any}>
            {children}
        </AuthContext.Provider>
    );
}
